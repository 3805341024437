export default [
	function () {
		function randomize(min, max) {
			return Math.random() * (max - min) + min;
		}

		function getRandomPromo(promotions) {
			let totalWeight = promotions.reduce((total, promotion) => {
				return total + promotion.weight;
			}, 0);

			let randomNumber = randomize(0, totalWeight);
			let currentWeight = 0;

			for (let promoIndex = 0; promoIndex < promotions.length; promoIndex++) {
				let promo = promotions[promoIndex];
				currentWeight += promo.weight;

				if (randomNumber < currentWeight) {
					return promo;
				}
			}

			return null;
		}

		function getPromoData() {
			let promotions = window.promotions || [];
			let randomizedPromo = null;

			promotions.forEach((promotion) => {
				promotion.weight = parseInt(promotion.weight, 10);
			});
			promotions = promotions.filter((promotion) => !isNaN(promotion.weight));

			if (promotions.length) {
				randomizedPromo = getRandomPromo(promotions);
			}

			return randomizedPromo;
		}

		return {
			getPromoData: getPromoData
		};
	}
];
