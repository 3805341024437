/* eslint max-lines: ["error", 1000] */
class ScrollHelper {
	constructor(Constants, $, $timeout) {
		this.constants = Constants;
		this.$ = $;
		this.$timeout = $timeout;

		this.RESULTS_PLACEHOLDER_SELECTOR = '#otud-result__matches-placeholder';
		this.RESULT_CONTAINER_SELECTOR = '.otud-result__matches';
		this.STICKY_CONTAINER_SELECTOR = '.otud-result__sticky';
	}

	scrollElementIntoView(selector, speed, positionToTop) {
		if (selector) {
			let element = this.$(selector);

			if (element.length) {
				let elementOffset = element.offset().top;
				let elementHeight = element.height();
				let windowHeight = this.$(window).height();
				let offset;

				if (elementHeight < elementOffset && !positionToTop) {
					offset = elementOffset - windowHeight / 2 - elementHeight / 2;
				} else {
					offset = elementOffset;
				}

				this.$('html, body').animate(
					{
						scrollTop: offset
					},
					isNaN(speed) ? 500 : speed
				);
			}
		}
	}

	scrollToResults(force) {
		if (window.innerWidth > this.constants.DESKTOP_VIEW_MIN_WIDTH || force) {
			this.scrollElementIntoView(this.RESULT_CONTAINER_SELECTOR, 500, true);
		}
	}

	initScrollWatcher(callback) {
		let searchResultIsInView = () => {
			let resultsPlaceholder = this.$(this.RESULTS_PLACEHOLDER_SELECTOR);
			let stickyContainer = this.$(this.STICKY_CONTAINER_SELECTOR);

			if (resultsPlaceholder.length && stickyContainer.length) {
				let $window = this.$(window);

				let windowHeight = $window.height();
				let stickyHeight = stickyContainer.height();
				let currentScrollPosition = $window.scrollTop();
				let resultContainerPosition = resultsPlaceholder.offset().top;

				if (currentScrollPosition > resultContainerPosition - windowHeight + stickyHeight) {
					this.removeScrollWatcher(searchResultIsInView);
					callback();
				} else {
					this.initInputWatcher(() => {
						this.removeScrollWatcher(searchResultIsInView);
						callback();
					});
				}
			} else {
				this.removeScrollWatcher(searchResultIsInView);
			}
		};

		this.removeScrollWatcher(searchResultIsInView);

		if (window.innerWidth <= this.constants.DESKTOP_VIEW_MIN_WIDTH) {
			this.$(window).scroll(searchResultIsInView);
			this.$timeout(() => {
				searchResultIsInView();
			});
		} else {
			callback();
		}
	}

	initInputWatcher(callback) {
		let searchForm = this.$('.otud-search__segment');
		let inputElements = searchForm.find('input');
		let focusCallback = () => {
			inputElements.off('focus', focusCallback);
			callback();
		};
		inputElements.on('focus', focusCallback);
	}

	removeScrollWatcher(fn) {
		this.$(window).off('scroll', fn);
	}

	orientationChanged() {
		const TIMEOUT = 120;

		return new window.Promise((resolve) => {
			let waitForIt = (i, height) => {
				window.innerHeight != height || i >= TIMEOUT ? resolve() : window.requestAnimationFrame(() => waitForIt(i + 1, height));
			};
			waitForIt(0, window.innerHeight);
		});
	}
}

ScrollHelper.$inject = ['Constants', 'jQuery', '$timeout'];

export default ScrollHelper;
