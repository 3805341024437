export default function () {
	function link($scope, element, attrs, modelCtrl) {
		let options = $scope.$eval(attrs.restrictInputCharacters);
		let characters = options.expression ? '[^' + options.expression + ']' : '.';
		let regExp = new RegExp(characters, 'g');

		element.on('input propertychange', () => {
			let inputValue = element.val();

			if (!inputValue) {
				inputValue = '';
			}

			let transformedInput = inputValue.replace(regExp, '');

			if (transformedInput.length == 0) {
				transformedInput = '';
			}

			if (transformedInput != inputValue) {
				modelCtrl.$setViewValue(transformedInput);
				modelCtrl.$render();
			}
		});
	}

	return {
		restrict: 'A',
		require: 'ngModel',
		priority: 100,
		link: link
	};
}
