import saveAs from './../../lib/lib_layoutid_RWOTUD/FileSaver';
export default [
	function () {
		function create(subscriber) {
			let name = `N:${subscriber.name2};${subscriber.name1};;;\n`;
			let fullName = `FN:${subscriber.name1} ${subscriber.name2}\n`;
			let tel = `TEL;TYPE=${subscriber.phoneNumber.type},VOICE:${subscriber.phoneNumber.formatted.replace(/\s/g, '-')}\n`;
			let url = subscriber.urls.length ? `URL:${subscriber.urls[0]}\n` : '';
			let email = subscriber.emails.length ? `EMAIL:${subscriber.emails[0]}\n` : '';
			let vCardBody = `${name}${fullName}${tel}${url}${email}`;
			return `BEGIN:VCARD\nVERSION:3.0\n${vCardBody}REV:${new Date().toISOString().replace(/-|:|\./g, '')}\nEND:VCARD`;
		}

		function download(vCardContent, fileName) {
			let blob = new Blob([vCardContent], { type: 'text/vcard' });
			saveAs(blob, fileName);
		}

		return {
			create: create,
			download: download
		};
	}
];
