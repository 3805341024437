export default [
	function () {
		let ACCENTED_CHAR_GROUPS = ['aá', 'eé', 'ií', 'oóöő', 'uúüű'];

		function ignoreSpaces(string) {
			return string.split('').join('\\s?');
		}

		function escapeRegExp(str) {
			return str.replace(/[+]/g, '\\$&');
		}

		function addAccentedToSearchText(searchText) {
			let result = [];

			for (let charIndex = 0; charIndex < searchText.length; charIndex++) {
				let char = searchText.charAt(charIndex).toLowerCase();
				let isVowel = false;

				for (let groupIndex = 0; groupIndex < ACCENTED_CHAR_GROUPS.length; groupIndex++) {
					let group = ACCENTED_CHAR_GROUPS[groupIndex];

					if (group.indexOf(char) > -1) {
						isVowel = true;
						result.push(`(${group.split('').join('|')})`);
						break;
					}
				}

				if (!isVowel) {
					result.push(char);
				}
			}

			return result.join('');
		}

		return {
			ignoreSpaces: ignoreSpaces,
			escapeRegExp: escapeRegExp,
			addAccentedToSearchText: addAccentedToSearchText
		};
	}
];
