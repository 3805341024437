export default [
	'Constants',
	function (constants) {
		function replaceNonDigitCharacters(phoneNumber) {
			return phoneNumber.replace(/\D/g, '');
		}

		function removeAccessCode(phoneNumber) {
			if (phoneNumber.length >= 10 && constants.INLAND_ACCESS_CODES.some((accessCode) => phoneNumber.indexOf(accessCode) == 0)) {
				return phoneNumber.substring(2);
			} else {
				return phoneNumber;
			}
		}

		function removeLeadingZero(phoneNumber) {
			return phoneNumber.replace(/^0+(.*)$/g, '$1');
		}

		function isBudapestAreaCode(areaCode) {
			return constants.BUDAPEST_AREA_CODES.some((budapestAreaCode) => budapestAreaCode == areaCode);
		}

		function isMobileOrCorporateAreaCode(number) {
			return constants.MOBILE_OR_CORPORATE_AREA_CODES.some((areaCode) => number.indexOf(areaCode) == 0);
		}

		function isM2MAreaCode(number) {
			return constants.M2M_AREA_CODES.some((areaCode) => number.indexOf(areaCode) == 0);
		}

		function isExistingAreaCode(areaCode) {
			return constants.NOT_USED_AREA_CODES.every((notUsedAreaCode) => notUsedAreaCode != areaCode);
		}

		function isBudapest(phoneNumber) {
			return /^\d{8}$/.test(phoneNumber) && isBudapestAreaCode(phoneNumber.substr(0, 1));
		}

		function isMobileOrCorporate(phoneNumber) {
			return isMobileOrCorporateAreaCode(phoneNumber) && /^\d{9}$/.test(phoneNumber);
		}

		function isM2M(phoneNumber) {
			return isM2MAreaCode(phoneNumber) && /^\d{12}$/.test(phoneNumber);
		}

		function isAreaBased(phoneNumber) {
			return /^\d{8}$/.test(phoneNumber) && isExistingAreaCode(phoneNumber.substr(0, 2));
		}

		function setPhoneNumberData(phoneNumberData, phoneNumber) {
			if (isBudapest(phoneNumber)) {
				phoneNumberData.area = phoneNumber.substr(0, 1);
				phoneNumberData.number = phoneNumber.substr(1);
				phoneNumberData.recognized = true;
			} else if (isMobileOrCorporate(phoneNumber) || isM2M(phoneNumber) || isAreaBased(phoneNumber)) {
				phoneNumberData.area = phoneNumber.substr(0, 2);
				phoneNumberData.number = phoneNumber.substr(2);
				phoneNumberData.recognized = true;
			}
		}

		function getParsedPhoneNumber(phoneNumber) {
			let phoneNumberData = {
				area: null,
				number: null,
				recognized: false
			};

			phoneNumber = replaceNonDigitCharacters(phoneNumber);
			phoneNumber = removeAccessCode(phoneNumber);

			if (phoneNumber.length > 7) {
				setPhoneNumberData(phoneNumberData, phoneNumber);
			}

			return phoneNumberData;
		}

		return {
			getParsedPhoneNumber: getParsedPhoneNumber,
			replaceNonDigitCharacters: replaceNonDigitCharacters,
			removeLeadingZero: removeLeadingZero,
			isBudapestAreaCode: isBudapestAreaCode,
			isMobileOrCorporateAreaCode: isMobileOrCorporateAreaCode,
			isM2MAreaCode: isM2MAreaCode,
			isExistingAreaCode: isExistingAreaCode
		};
	}
];
