/* eslint max-lines: ["error", 1000] */
class OnlineTudakozoService {
	constructor(Constants, PhoneNumberParser, $http, $q) {
		this.constants = Constants;
		this.PhoneNumberParser = PhoneNumberParser;
		this.$http = $http;
		this.$q = $q;
	}

	validatePhoneNumber(phoneNumberModel) {
		let validationResult = {
			validPhoneNumber: true,
			errorKeys: {
				phoneArea: {
					notExist: true
				},
				phoneNumber: {
					require6Digits: true,
					require7Digits: true,
					require10Digits: true
				}
			}
		};

		if (phoneNumberModel.area && phoneNumberModel.number) {
			let area = this.PhoneNumberParser.removeLeadingZero(phoneNumberModel.area);
			let number = this.PhoneNumberParser.replaceNonDigitCharacters(phoneNumberModel.number);

			let setErrorCodesForNumber = (require6Digits, require7Digits, require10Digits) => {
				validationResult.errorKeys.phoneNumber.require6Digits = require6Digits;
				validationResult.errorKeys.phoneNumber.require7Digits = require7Digits;
				validationResult.errorKeys.phoneNumber.require10Digits = require10Digits;
				validationResult.validPhoneNumber = require6Digits && require7Digits && require10Digits;
			};

			let isBudapestAreaCode = this.PhoneNumberParser.isBudapestAreaCode(area);
			let isMobileOrCorporateAreaCode = this.PhoneNumberParser.isMobileOrCorporateAreaCode(area);
			let isM2MAreaCode = this.PhoneNumberParser.isM2MAreaCode(area);

			if ((isBudapestAreaCode || isMobileOrCorporateAreaCode) && number.length != 7) {
				setErrorCodesForNumber(true, false, true);
			} else if (isM2MAreaCode && number.length != 10) {
				setErrorCodesForNumber(true, true, false);
			} else if (!isBudapestAreaCode && !isMobileOrCorporateAreaCode && !isM2MAreaCode) {
				if (this.PhoneNumberParser.isExistingAreaCode(area)) {
					if (number.length != 6) {
						setErrorCodesForNumber(false, true, true);
					}
				} else {
					validationResult.errorKeys.phoneArea.notExist = false;
					validationResult.validPhoneNumber = false;
				}
			}
		} else {
			if (phoneNumberModel.area) {
				let area = this.PhoneNumberParser.removeLeadingZero(phoneNumberModel.area);
				validationResult.errorKeys.phoneArea.notExist = this.PhoneNumberParser.isExistingAreaCode(area);
			}
			validationResult.validPhoneNumber = false;
		}

		return validationResult;
	}

	getAutoSuggestData(category, searchData, callback) {
		let canceler = this.$q.defer();

		this.$http
			.post(
				this.constants.AUTO_SUGGEST_URL,
				{
					category: category,
					searchData: searchData
				},
				{
					timeout: canceler.promise
				}
			)
			.then(
				(response) => {
					callback(response.data.entity.response);
				},
				(response) => {
					console.log(`${response.config.method} ${response.config.url} ERROR [${response.status}]: ${response.data}`);
				}
			);

		return canceler;
	}

	getSearchParams(formData, searchType, captcha) {
		let searchParams = {
			recaptcha: captcha.response,
			searchType: searchType.toUpperCase()
		};

		switch (searchType) {
			case this.constants.SEARCH_TYPES.PERSONAL.key:
				this.addPersonalSearchParams(formData, searchParams);
				break;
			case this.constants.SEARCH_TYPES.PHONE_NUMBER.key:
				this.addPhoneNumberSearchParams(formData, searchParams);
				break;
			case this.constants.SEARCH_TYPES.COMPANY.key:
				this.addCompanySearchParams(formData, searchParams);
				break;
			default:
		}

		return searchParams;
	}

	addPersonalSearchParams(formData, searchParams) {
		searchParams.name1 = formData.lastName;
		searchParams.name2 = formData.firstName;
		searchParams.city = formData.cityValue || formData.city;
		searchParams.street = formData.street;
		searchParams.searchMode = formData.mode;

		let parts = this.parseHouseNumber(formData.houseNum);
		searchParams.houseNr = parts.number;
		searchParams.houseNrSupplement = parts.supplement;
	}

	addPhoneNumberSearchParams(formData, searchParams) {
		searchParams.fullNr = formData.area + formData.number;
	}

	addCompanySearchParams(formData, searchParams) {
		searchParams.name2 = formData.name;
		searchParams.city = formData.cityValue || formData.city;
		searchParams.street = formData.street;
		searchParams.searchMode = formData.mode;

		let parts = this.parseHouseNumber(formData.houseNum);
		searchParams.houseNr = parts.number;
		searchParams.houseNrSupplement = parts.supplement;
	}

	parseHouseNumber(houseNr) {
		let houseNrParts = {
			number: null,
			supplement: null
		};

		if (houseNr) {
			let houseNrRegex = /^(\d+)(.*)/;

			if (houseNrRegex.test(houseNr)) {
				let parts = houseNrRegex.exec(houseNr);
				houseNrParts.number = parseInt(parts[1], 10);
				houseNrParts.supplement = parts[2];
			} else {
				houseNrParts.supplement = houseNr;
			}
		}

		return houseNrParts;
	}

	getSearchResult(params, callback) {
		let canceler = this.$q.defer();

		this.$http
			.post(this.constants.SEARCH_URL, params, {
				timeout: canceler.promise
			})
			.then(
				(response) => {
					if (response.data.entity) {
						callback(response.data.entity);
					} else {
						this.handleException(callback);
					}
				},
				(response) => {
					console.log(`${response.config.method} ${response.config.url} ERROR [${response.status}]: ${response.data}`);
					this.handleException(callback);
				}
			);

		return canceler;
	}

	handleException(callback) {
		callback({
			status: this.constants.SEARCH_STATUSES.ERROR,
			data: {}
		});
	}

	initSuggestions(autoSuggestModel, autoSuggestResult, isCitySearch) {
		let suggestions = isCitySearch ? autoSuggestResult.matchCity : autoSuggestResult.matchStreet;
		let limit = isNaN(window.autoSuggestLimit) ? this.constants.DEFAULT_AUTO_SUGGEST_LIMIT : window.autoSuggestLimit;
		autoSuggestModel.suggestions = suggestions.slice(0, limit);

		if (isCitySearch) {
			autoSuggestModel.suggestions.forEach((suggestion) => {
				suggestion.areaCode = suggestion.areaCode ? suggestion.areaCode + '' : null;
			});
		}

		this.sortSuggestions(autoSuggestModel.suggestions, isCitySearch);
	}

	sortSuggestions(suggestions, isCitySearch) {
		const LOCALE_CODE = 'hu-HU';

		try {
			'test'.localeCompare('string', LOCALE_CODE);
			if (isCitySearch) {
				suggestions.sort((s1, s2) => s1.suggest.localeCompare(s2.suggest, LOCALE_CODE));
			} else {
				suggestions.sort((s1, s2) => s1.localeCompare(s2, LOCALE_CODE));
			}
		} catch (e) {
			// nem tamogatott rendezesnel amilyen sorrendben jon, olyanban jelenik meg
		}
	}

	isIOSOrAndroid() {
		let userAgent = window.navigator.userAgent;
		let isAndroid = /android/i.test(userAgent);
		let isIOS = /iPad|iPhone|iPod/.test(userAgent);
		return (isAndroid || isIOS) && !window.MSStream;
	}
}

OnlineTudakozoService.$inject = ['Constants', 'PhoneNumberParser', '$http', '$q'];

export default OnlineTudakozoService;
