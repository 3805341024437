/* eslint max-lines: ["error", 300] */
import OnlineTudakozoController from './controllers/OnlineTudakozoController';

import jQuery from './factories/jQuery';
import PhoneNumberParser from './factories/PhoneNumberParser';
import SearchResultConverter from './factories/SearchResultConverter';
import HighlightHelpers from './factories/HighlightHelpers';
import vCard from './factories/vCard';
import PromoHandler from './factories/PromoHandler';

import OnlineTudakozoService from './services/OnlineTudakozoService';
import ScrollHelper from './services/ScrollHelper';
import TagManager from './services/TagManager';

import StopPropagation from './directives/StopPropagation';
import DesignSelect from './directives/DesignSelect';
import HidePlaceholder from './directives/HidePlaceholder';
import RestrictInputCharacters from './directives/RestrictInputCharacters';
import Scroll from './directives/Scroll';
import AutosuggestCloser from './directives/AutosuggestCloser';
import AutosuggestScrollWithArrows from './directives/AutosuggestScrollWithArrows';
import PhoneNumberPositioner from './directives/PhoneNumberPositioner';

import Unsafe from './filters/Unsafe';
import Highlight from './filters/Highlight';
import HighlightSearchFilterMatches from './filters/HighlightSearchFilterMatches';

const constants = {
	SEARCH_TYPES: {
		PERSONAL: {
			key: 'resident',
			model: 'personal',
			value: 'nev'
		},
		PHONE_NUMBER: {
			key: 'number',
			model: 'phoneNumber',
			value: 'telefonszam'
		},
		COMPANY: {
			key: 'business',
			model: 'company',
			value: 'ceg'
		}
	},
	SEARCH_MODES: {
		MATCH: 'MATCH',
		INITIAL: 'INITIAL',
		SYNONYM: 'SYNONYM'
	},
	HINT_TOOLTIPS: {
		LAST_NAME: 'lastName',
		FIRST_NAME: 'firstName',
		PHONE_NUMBER: 'phoneNumber',
		COMPANY_NAME: 'companyName'
	},
	AUTO_SUGGEST_TYPES: {
		CITY: 'city',
		STREET: 'street'
	},
	AUTO_SUGGEST_SUGGESSTION_TIMEOUT: 300,
	AUTO_SUGGEST_CHECK_MATCH_TIMEOUT: 2000,
	AUTO_SUGGEST_URL: '/lakossagi/onlinetudi/api/das-online/auto-suggest',
	DEFAULT_AUTO_SUGGEST_LIMIT: 5,
	SEARCH_URL: '/lakossagi/onlinetudi/api/das-online/search',
	SEARCH_TIMEOUT: 15000,
	ENTER_KEYCODE: 13,
	TAB_KEYCODE: 9,
	OFFICIAL_ACCESS_CODE: '+36',
	INLAND_ACCESS_CODES: ['06', '36'],
	BUDAPEST_AREA_CODES: ['1'],
	MOBILE_OR_CORPORATE_AREA_CODES: ['20', '21', '30', '31', '38', '50', '70'],
	M2M_AREA_CODES: ['71'],
	NOT_USED_AREA_CODES: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '39', '41', '43', '58', '60', '61', '64', '65', '67', '81', '86', '97', '98'],
	SEARCH_STATUSES: {
		SUCCESS: 'success',
		FAIL: 'fail',
		ERROR: 'error'
	},
	HIERARCHY_LEVELS: {
		TOP: 0,
		SECOND: 1
	},
	DEFAULT_PROMO_POSITION: 6,
	MOBILE_VIEW_MAX_WIDTH: 480,
	DESKTOP_VIEW_MIN_WIDTH: 768,
	TOO_MUCH_RECORDS: 4000,
	INVALID_CAPTCHA_CODE: 'invalid-captcha',
	WAIT_PHONENUMBER_VALIDATION_IN_MS: 1200,
	PHONENUMBER_ON_VALIDATE_MIN_LENGTH: 5,
	PHONEAREA_ON_VALIDATE_MIN_LENGTH: 1,
	EXTRA_INFO_TYPES: {
		DM: 'DM'
	},
	MOBILE_BREAKPOINT: 480
};

angular
	.module('onlineTudi', ['vcRecaptcha', 'ngMessages', 'ngSanitize'])
	.constant('Constants', constants)
	.controller('OnlineTudakozoController', OnlineTudakozoController)
	.factory('jQuery', [jQuery])
	.factory('PhoneNumberParser', PhoneNumberParser)
	.factory('SearchResultConverter', SearchResultConverter)
	.factory('HighlightHelpers', HighlightHelpers)
	.factory('vCard', vCard)
	.factory('PromoHandler', PromoHandler)
	.service('OnlineTudakozoService', OnlineTudakozoService)
	.service('ScrollHelper', ScrollHelper)
	.service('TagManager', TagManager)
	.directive('stopPropagation', StopPropagation)
	.directive('designSelect', DesignSelect)
	.directive('hidePlaceholder', HidePlaceholder)
	.directive('restrictInputCharacters', RestrictInputCharacters)
	.directive('scroll', Scroll)
	.directive('autosuggestCloser', AutosuggestCloser)
	.directive('autosuggestScrollWithArrows', AutosuggestScrollWithArrows)
	.directive('phoneNumberPositioner', PhoneNumberPositioner)
	.filter('unsafe', Unsafe)
	.filter('highlight', Highlight)
	.filter('highlightSearchFilterMatches', HighlightSearchFilterMatches);
