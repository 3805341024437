class TagManager {
	constructor() {}

	logPromoDisplay(promo, promoPosition) {
		let data = {
			ecommerce: {
				promoView: {
					promotions: [
						{
							id: promo.contentId,
							position: promoPosition
						}
					]
				}
			}
		};

		this.addHitToDataLayer(data);
	}

	logPromoClick(promo, promoPosition) {
		let data = {
			event: 'promotionClick',
			ecommerce: {
				promoClick: {
					promotions: [
						{
							id: promo.contentId,
							position: promoPosition
						}
					]
				}
			}
		};

		this.addHitToDataLayer(data);
	}

	logSearch(searchParams, searchType) {
		let data = {
			event: 'search',
			searchType: searchType,
			searchMode: searchParams.mode ? searchParams.mode.toLowerCase() : '',
			lastName: searchParams.lastName || '',
			firstName: searchParams.firstName || '',
			companyName: searchParams.name || '',
			phoneNumber: searchParams.area ? searchParams.area + searchParams.number : '',
			city: searchParams.city || '',
			street: searchParams.street || '',
			houseNum: searchParams.houseNum || ''
		};

		this.addHitToDataLayer(data);
	}

	addHitToDataLayer(data) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(data);
	}
}

TagManager.$inject = [];

export default TagManager;
