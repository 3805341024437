export default [
	'Constants',
	'jQuery',
	'$timeout',
	'ScrollHelper',
	function (constants, $, $timeout, ScrollHelper) {
		const RECORD_SELECTOR = '.otud-result__match';
		const ROW_SELECTOR = '.otud-result__main-row';
		const NAME_SELECTOR = '.otud-result__name';
		const FULL_NAME_WIDTH_CLASS = 'full-width';

		function calculateWidths(records) {
			let maxWidth = 0;

			records.each((i, element) => {
				let record = $(element);
				let row = record.find(ROW_SELECTOR);
				let name = record.find(NAME_SELECTOR);

				name.removeClass(FULL_NAME_WIDTH_CLASS).removeAttr('style');
				let nameWidth = name.outerWidth(true);

				if (row.outerWidth(true) / 2 < nameWidth) {
					name.addClass(FULL_NAME_WIDTH_CLASS);
				} else if (nameWidth > maxWidth) {
					maxWidth = nameWidth;
				}
			});

			records.find(NAME_SELECTOR).innerWidth(maxWidth + 1);
		}

		function startCalculation(element) {
			$timeout(() => {
				let records = element.find(RECORD_SELECTOR);

				if (window.innerWidth > constants.MOBILE_VIEW_MAX_WIDTH) {
					calculateWidths(records);
				} else {
					records.find(NAME_SELECTOR).removeClass(FULL_NAME_WIDTH_CLASS).removeAttr('style');
				}
			});
		}

		function link($scope, element) {
			$scope.$on('calculatePhoneNumberPositions', () => {
				startCalculation(element);
			});

			$(window).on('orientationchange', () => {
				if (window.Promise) {
					ScrollHelper.orientationChanged().then(() => {
						startCalculation(element);
					});
				}
			});

			startCalculation(element);
		}

		return {
			restrict: 'A',
			scope: {
				autosuggestCloser: '&'
			},
			link: link
		};
	}
];
