var designRadioCheckboxLoaded = false;
var designRadioCheckboxOnload = (function () {
	// Mozilla, Opera and webkit nightlies currently support this event
	if (document.addEventListener) {
		document.addEventListener(
			'DOMContentLoaded',
			function contentLoaded() {
				document.removeEventListener('DOMContentLoaded', contentLoaded, false);
				designRadioCheckboxOnLoaded();
			},
			false
		);
	}
})();

var ie = (function () {
	var undef,
		v = 3,
		div = document.createElement('div'),
		all = div.getElementsByTagName('i');

	while (((div.innerHTML = '<!--[if gt IE ' + ++v + ']><i></i><![endif]-->'), all[0]));

	return v > 4 ? v : undef;
})();

function designRadioCheckboxOnLoaded() {
	designRadioCheckboxLoaded = true;
	function hasClass(elem, className) {
		return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
	}
	function addClass(elem, className) {
		if (!hasClass(elem, className)) {
			elem.className += ' ' + className;
			if (ie < 9) {
				document.body.className = document.body.className;
			}
		}
	}
	function removeClass(elem, className) {
		var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
		if (hasClass(elem, className)) {
			while (newClass.indexOf(' ' + className + ' ') >= 0) {
				newClass = newClass.replace(' ' + className + ' ', ' ');
			}
			elem.className = newClass.replace(/^\s+|\s+$/g, '');
			if (ie < 9) {
				document.body.className = document.body.className;
			}
		}
	}
	/**
	 * a következő struktúrán tud működni: <label class="designlabel">cimke<input /></label> ezekre automatikusan ráfut. a cimke string megadása nem kötelező,
	 * az input tipusa checkbox vagy radio lehet ahol bepipált az érték, ott a label kap egy "c_on" vagy "r_on" class-t, ellenkező esetben nem
	 *
	 * @since 2012.12
	 * @author sarkiroka
	 */
	function setupLabel() {
		//jelezzük hogy dizájnolt elemek
		var checkboxes = document.querySelectorAll('label.designlabel > input[type="checkbox"]');
		for (var i = 0, iLen = checkboxes.length; i < iLen; i++) {
			addClass(checkboxes[i].parentNode, 'label_check');
		}
		var radios = document.querySelectorAll('label.designlabel > input[type="radio"]');
		for (var i = 0, iLen = radios.length; i < iLen; i++) {
			addClass(radios[i].parentNode, 'label_radio');
		}

		if (document.querySelectorAll('.label_check input').length) {
			var labels = document.querySelectorAll('.label_check');
			for (var i = 0, iLen = labels.length; i < iLen; i++) {
				removeClass(labels[i], 'c_on');
			}
			var checkboxes = document.querySelectorAll('.label_check input');
			for (var i = 0, iLen = checkboxes.length; i < iLen; i++) {
				if (checkboxes[i].checked) {
					addClass(checkboxes[i].parentNode, 'c_on');
				}
			}
		}
		if (document.querySelectorAll('.label_radio input').length) {
			var labels = document.querySelectorAll('.label_radio');
			for (var i = 0, iLen = labels.length; i < iLen; i++) {
				removeClass(labels[i], 'r_on');
			}
			var radios = document.querySelectorAll('.label_radio input');
			for (var i = 0, iLen = radios.length; i < iLen; i++) {
				if (radios[i].checked) {
					addClass(radios[i].parentNode, 'r_on');
				}
			}
		}
	}

	var checkboxes = document.querySelectorAll('label.designlabel > input[type="checkbox"]');
	for (var i = 0, iLen = checkboxes.length; i < iLen; i++) {
		checkboxes[i].onclick = function (e) {
			setupLabel();
			if (ie < 9) {
				this.blur();
			}
		};
		checkboxes[i].onchange = function (e) {
			setupLabel();
			if (ie < 9) {
				this.blur();
			}
		};

		setupLabel();
	}
	var radios = document.querySelectorAll('label.designlabel > input[type="radio"]');
	for (var i = 0, iLen = radios.length; i < iLen; i++) {
		radios[i].onclick = function (e) {
			setupLabel();
			if (ie < 9) {
				this.blur();
			}
		};
		radios[i].onchange = function (e) {
			setupLabel();
			if (ie < 9) {
				this.blur();
			}
		};

		setupLabel();
	}
	/**
	 * frissiti a dizájnos labelek megjelenését a dizájnolt elemek állapotától függően
	 *
	 * @author sarkiroka
	 */
	window.designRadioCheckboxRefresh = function () {
		setupLabel();
	};
	/**
	 * befrissiti a dizájnos labeleket és újra ráfűzi az eseménykezelőket
	 *
	 * @author sarkiroka
	 */
	window.designRadioCheckboxRefreshListener = function () {
		var checkboxes = document.querySelectorAll('label.designlabel > input[type="checkbox"]');
		for (var i = 0, iLen = checkboxes.length; i < iLen; i++) {
			checkboxes[i].onclick = function (e) {
				setupLabel();
				if (ie < 9) {
					this.blur();
				}
			};
			checkboxes[i].onchange = function (e) {
				setupLabel();
				if (ie < 9) {
					this.blur();
				}
			};
		}
		var radios = document.querySelectorAll('label.designlabel > input[type="radio"]');
		for (var i = 0, iLen = radios.length; i < iLen; i++) {
			radios[i].onclick = function (e) {
				setupLabel();
				if (ie < 9) {
					this.blur();
				}
			};
			radios[i].onchange = function (e) {
				setupLabel();
				if (ie < 9) {
					this.blur();
				}
			};
		}

		setupLabel();
	};
}
