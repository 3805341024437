export default [
	'HighlightHelpers',
	function (HighlightHelpers) {
		return function (text, searchText) {
			if (text && searchText) {
				let searchTextWithSpaces = HighlightHelpers.ignoreSpaces(searchText);
				let escapedSearchText = HighlightHelpers.escapeRegExp(searchTextWithSpaces);
				var regexp = new RegExp('(' + escapedSearchText + ')', 'i');
				return text.replace(regexp, '<strong>$1</strong>');
			}
			return '';
		};
	}
];
