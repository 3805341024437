/**
 * [ScrollWithArrows scrollModel object]
 * @param  {string} selectorClass a kivalasztott elemre kerul ra (hover effect), pont nelkul kell beallitani
 * @param  {string} scrolledElements css osztaly, ami a scrollozhato elemlistara mutat
 * @param  {string} scrollDirectiveElement ezen az elemen van a data-scroll direktiva
 * @param  {string} inputSelectorClass az a html elem, amelyen a gomb lenyomast figyeli
 */
export default [
	function () {
		function link($scope, element, attrs) {
			var scrollModel = {
				selectorClass: 'selected',
				scrolledElements: '.scroll-elements',
				scrollDirectiveElement: '.scroll-wrapper',
				inputSelectorClass: '.search-input'
			};
			var configuration = $scope.$eval(attrs.autosuggestScrollWithArrows);

			angular.extend(scrollModel, configuration);

			function isElementInViewport(selectedElement, content) {
				var contentRect = element.find(content)[0].getBoundingClientRect();
				var rect = selectedElement[0].getBoundingClientRect();

				return rect.top >= 0 && rect.top >= contentRect.top && rect.bottom <= contentRect.bottom;
			}

			function scrollToElementIfOutOfContent(selectedElement, parentElement) {
				if (!isElementInViewport(selectedElement, parentElement)) {
					element.find(parentElement).mCustomScrollbar('scrollTo', selectedElement, { scrollInertia: 0 });
				}
			}

			function downOrUpArrowPressed(isUpArrow) {
				var selectedElement = element.find(scrollModel.scrollDirectiveElement + ' .' + scrollModel.selectorClass);
				var prevOrNextElement;
				var lastOrFirstElement;

				if (isUpArrow) {
					prevOrNextElement = selectedElement.prev();
					lastOrFirstElement = element.find(scrollModel.scrolledElements).last();
				} else {
					prevOrNextElement = selectedElement.next();
					lastOrFirstElement = element.find(scrollModel.scrolledElements).first();
				}

				element.find(scrollModel.scrolledElements).removeClass(scrollModel.selectorClass);
				if (selectedElement.length === 0) {
					lastOrFirstElement.addClass(scrollModel.selectorClass);
				} else if (prevOrNextElement.length === 0) {
					lastOrFirstElement.addClass(scrollModel.selectorClass);
					element.find(scrollModel.scrollDirectiveElement).mCustomScrollbar('scrollTo', lastOrFirstElement, { scrollInertia: 0 });
				} else {
					prevOrNextElement.addClass(scrollModel.selectorClass);
					scrollToElementIfOutOfContent(prevOrNextElement, scrollModel.scrollDirectiveElement);
				}
			}

			function enterPressed(input, e) {
				var selectedElement = element.find(scrollModel.scrollDirectiveElement + ' .' + scrollModel.selectorClass);

				if (selectedElement.length) {
					selectedElement.triggerHandler('click');
					element.find(input).blur();
				}

				if (selectedElement.length || element.find(scrollModel.scrolledElements).length) {
					e.preventDefault(); // prevent form submit
				}
			}

			element.find(scrollModel.inputSelectorClass).keydown(function (e) {
				if (e.keyCode == 40) {
					downOrUpArrowPressed(false);
				} else if (e.keyCode == 38) {
					downOrUpArrowPressed(true);
				} else if (e.keyCode == 13) {
					enterPressed(this, e);
				}
			});
		}

		return {
			restrict: 'A',
			link: link
		};
	}
];
