export default function () {
	function link($scope, element, attrs) {
		let placeHolderBackUp = '';

		element
			.on('focus', () => {
				placeHolderBackUp = attrs.placeholder;
				element.attr('placeholder', '');
			})
			.on('blur', () => {
				element.attr('placeholder', placeHolderBackUp);
			});
	}

	return {
		restrict: 'A',
		require: '^ngModel',
		link: link
	};
}
