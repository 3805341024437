export default function () {
	return {
		link: function ($scope, $element) {
			$element.mCustomScrollbar();
			$element.on('selectedChanged', function () {
				var selected = $element.find('.selected');
				if (!selected.is(':mcsInView') && selected.length > 0) {
					$element.mCustomScrollbar('scrollTo', selected[0].parentElement);
				}
			});
		}
	};
}
