/* eslint max-lines: ["error", 200] */
export default [
	'Constants',
	'PhoneNumberParser',
	function (constants, PhoneNumberParser) {
		function getEmailsAndUrls(subscriber) {
			subscriber.emails = [];
			subscriber.urls = [];

			if (Array.isArray(subscriber.additionalInfos)) {
				let emailPattern = /.+@.+\..+/;

				subscriber.additionalInfos.forEach((additionalInfo) => {
					if (emailPattern.test(additionalInfo)) {
						subscriber.emails.push(additionalInfo);
					} else {
						subscriber.urls.push(additionalInfo);
					}
				});
			}
		}

		function formatPhoneNumber(number, searchParams) {
			const PHONE_NUMBER_TYPES = {
				MOBILE: 'CELL',
				HOME: 'HOME',
				WORK: 'WORK'
			};

			let phoneNumber = {
				unformatted: null,
				formatted: null,
				type: null,
				matchWithSearchPhrase: false
			};

			if (number) {
				let phoneNumberData = PhoneNumberParser.getParsedPhoneNumber(number);
				let isPhoneNumberSearch = searchParams.searchType == constants.SEARCH_TYPES.PHONE_NUMBER.key.toUpperCase();
				phoneNumber.matchWithSearchPhrase = isPhoneNumberSearch && searchParams.fullNr == number;

				if (phoneNumberData.recognized) {
					let formattedNumber = '';
					if (phoneNumberData.number.length == 6) {
						formattedNumber = phoneNumberData.number.replace(/(\d{3})(\d{3})/, '$1 $2');
						phoneNumber.type = PHONE_NUMBER_TYPES.HOME;
					} else if (phoneNumberData.number.length == 7) {
						formattedNumber = phoneNumberData.number.replace(/(\d{3})(\d{4})/, '$1 $2');
						phoneNumber.type = phoneNumberData.area.length == 1 ? PHONE_NUMBER_TYPES.HOME : PHONE_NUMBER_TYPES.MOBILE;
					} else if (phoneNumberData.number.length == 10) {
						formattedNumber = phoneNumberData.number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
						phoneNumber.type = PHONE_NUMBER_TYPES.WORK;
					}
					phoneNumber.unformatted = `${constants.OFFICIAL_ACCESS_CODE}${phoneNumberData.area}${phoneNumberData.number}`;
					phoneNumber.formatted = `${constants.OFFICIAL_ACCESS_CODE} ${phoneNumberData.area} ${formattedNumber}`;
				} else {
					phoneNumber.unformatted = phoneNumber.formatted = '+' + number;
					phoneNumber.type = PHONE_NUMBER_TYPES.MOBILE;
				}
			}

			return phoneNumber;
		}

		function getHouseNumber(houseNr, houseNrSupplement) {
			let houseNum;
			if (!houseNr && !houseNrSupplement) {
				houseNum = '';
			} else if (!houseNrSupplement) {
				houseNum = `${houseNr}.`;
			} else if (!houseNr && houseNrSupplement) {
				houseNum = `${houseNrSupplement}`;
			} else if (houseNrSupplement.indexOf('-') >= 0 || houseNrSupplement.indexOf('/') >= 0) {
				houseNum = `${houseNr}${houseNrSupplement}`;
			} else {
				houseNum = `${houseNr}. ${houseNrSupplement}`;
			}

			return houseNum;
		}

		function getMailToLink(subscriber) {
			const MAIL_TO_LINK_BASE = 'mailto:';
			const SUBJECT_PARAM = 'subject=';
			const BODY_PARAM = 'body=';
			const NEW_LINE_CODE = '%0D%0A';

			let name1NullSafe = subscriber.name1 ? subscriber.name1 : '';
			let name2NullSafe = subscriber.name2 ? ' ' + subscriber.name2 : '';

			let subscriberName = window.encodeURI(`${name1NullSafe}${name2NullSafe}`);
			let subscriberPhoneNumber = window.encodeURI(subscriber.phoneNumber.formatted);
			let subscriberAddress = window.encodeURI(`${subscriber.zip} ${subscriber.locName}, ${subscriber.street} ${subscriber.houseNum}`);

			let subject = `${subscriberName}%20névjegye`;
			let body = `${subscriberName}${NEW_LINE_CODE}${subscriberPhoneNumber}${NEW_LINE_CODE}${subscriberAddress}`;

			return `${MAIL_TO_LINK_BASE}?${SUBJECT_PARAM}${subject}&${BODY_PARAM}${body}`;
		}

		function convert(subscribers, searchParams) {
			let result = [];

			subscribers.forEach((subscriber) => {
				subscriber.fullNr = subscriber.fullNr ? subscriber.fullNr + '' : null;
				subscriber.zip = subscriber.zip ? subscriber.zip + '' : null;

				getEmailsAndUrls(subscriber);
				subscriber.phoneNumber = formatPhoneNumber(subscriber.fullNr, searchParams);
				subscriber.street = subscriber.pubDomainName ? `${subscriber.pubDomainName} ${subscriber.pubDomainCatName}` : '';
				subscriber.houseNum = getHouseNumber(subscriber.houseNr, subscriber.houseNrSupplement);

				let lastResult = result[result.length - 1];

				if (subscriber.hierarchyLevel == constants.HIERARCHY_LEVELS.TOP) {
					subscriber.mailToLink = getMailToLink(subscriber);
					subscriber.connectedSubscribers = [];
					result.push(subscriber);
				} else if (subscriber.hierarchyLevel == constants.HIERARCHY_LEVELS.SECOND && lastResult) {
					subscriber.connectedSubscribers = [];
					lastResult.connectedSubscribers.push(subscriber);
				} else if (lastResult) {
					let lastConnectedSubscriber = lastResult.connectedSubscribers[lastResult.connectedSubscribers.length - 1];
					if (lastConnectedSubscriber) {
						lastConnectedSubscriber.connectedSubscribers.push(subscriber);
					} else {
						// ha kimarad a második szintű subscribe, akkor berakjuk az első szintűbe
						lastResult.connectedSubscribers.push(subscriber);
					}
				}
			});

			return result;
		}

		return {
			convert: convert
		};
	}
];
