/*
	autoSuggest lenyilo becsukasat vegzi, ha nem az elemenen kattintunk
	ehhez egy scope metodust var, ami ezt a funkciot vegzi
	a direktiva mellett a stopPropagation direktivat kell hasznalni, hogy magan az elemen ne valtodjon ki az esemeny
*/
export default [
	'jQuery',
	'$timeout',
	function ($, $timeout) {
		function link($scope) {
			var clickHandler = function () {
				$timeout(function () {
					$scope.autosuggestCloser({
						param: false
					});
				});
			};

			$(document).on('click', clickHandler);

			$scope.$on('$destroy', function () {
				$(document).off('click', clickHandler);
			});
		}

		return {
			restrict: 'A',
			scope: {
				autosuggestCloser: '&'
			},
			link: link
		};
	}
];
