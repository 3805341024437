export default [
	'HighlightHelpers',
	function (HighlightHelpers) {
		return function (text, searchText) {
			if (text && searchText) {
				let searchTextWithSpaces = HighlightHelpers.ignoreSpaces(searchText);
				let regexp = new RegExp('(' + HighlightHelpers.addAccentedToSearchText(searchTextWithSpaces) + ')', 'gi');
				return text.replace(regexp, '<span class="is-highlighted">$1</span>');
			}
			return text;
		};
	}
];
