export default function () {
	function link($scope, elem, attrs) {
		$scope.$watch(attrs.ngModel, function () {
			setTimeout(function () {
				if (window.designSelectRefresh) {
					window.designSelectRefresh(elem);
				}
			}, 0);
		});

		setTimeout(function () {
			if (window.designSelect) {
				window.designSelect(elem);
			}
		});
	}

	return {
		restrict: 'A',
		require: '^ngModel',
		link: link
	};
}
