/* eslint max-lines: ["error", 1000] */
class OnlineTudakozoController {
	constructor(Constants, $scope, $timeout, vcRecaptchaService, PhoneNumberParser, OnlineTudakozoService, ScrollHelper, SearchResultConverter, vCard, PromoHandler, TagManager) {
		this.constants = Constants;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.vcRecaptchaService = vcRecaptchaService;
		this.PhoneNumberParser = PhoneNumberParser;
		this.OnlineTudakozoService = OnlineTudakozoService;
		this.ScrollHelper = ScrollHelper;
		this.SearchResultConverter = SearchResultConverter;
		this.vCard = vCard;
		this.PromoHandler = PromoHandler;
		this.TagManager = TagManager;

		this.searchType = this.getInitialSearchType();
		this.search = {
			personal: {
				mode: this.constants.SEARCH_MODES.MATCH,
				autosuggest: {
					city: {},
					street: {}
				}
			},
			phoneNumber: {
				timeoutPromise: null,
				autosuggest: {
					city: {}
				}
			},
			company: {
				mode: this.constants.SEARCH_MODES.MATCH,
				autosuggest: {
					city: {},
					street: {}
				}
			}
		};
		this.visibleTooltips = {};
		this.visibleSubscribeTooltip = null;
		this.autoSuggestCanceler = null;
		this.searchCanceler = null;
		this.cityCheckMatchTimeout = null;
		this.loaderTimeout = null;

		this.captcha = {
			show: true,
			widgetId: null,
			response: null,
			fail: false
		};

		this.showLoader = false;
		this.searchResult = null;

		this.canSaveContact = this.OnlineTudakozoService.isIOSOrAndroid();

		this.$timeout(() => {
			this.validateSearchForms();
		});
		$scope.$watch('ot.searchType', () => {
			$timeout(() => this.searchTypeChange());
		});
	}

	getInitialSearchType() {
		let searchTypeParam = window.location.hash;
		if (searchTypeParam) {
			for (let searchType in this.constants.SEARCH_TYPES) {
				if (searchTypeParam.indexOf(this.constants.SEARCH_TYPES[searchType].value) > -1) {
					return this.constants.SEARCH_TYPES[searchType];
				}
			}
			return this.constants.SEARCH_TYPES.PERSONAL;
		} else {
			return this.constants.SEARCH_TYPES.PERSONAL;
		}
	}

	searchTypeChange() {
		window.location.hash = this.searchType.value;

		this.searchResult = null;
		this.validateSearchForms();
	}

	showHint(tooltipId) {
		this.visibleTooltips[tooltipId] = true;
		this.showHintOverlay = true;
	}

	showPopup(subscriber) {
		this.showPopupOverlay = true;
		this.popupSubscriberData = subscriber;
	}

	hideToolTipsAndOverlay() {
		this.showHintOverlay = false;
		this.showPopupOverlay = false;

		for (let tooltipId in this.visibleTooltips) {
			this.visibleTooltips[tooltipId] = false;
		}

		this.closeSubscriberTooltipHint();
	}

	toggleTooltip(selectedSubscriber, state) {
		if (this.isMobileView) {
			this.showHintOverlay = state;
		}

		if (state) {
			this.closeSubscriberTooltipHint();
			this.visibleSubscribeTooltip = selectedSubscriber;
		}

		selectedSubscriber.tooltipHintOpen = state;
	}

	closeSubscriberTooltipHint() {
		if (this.visibleSubscribeTooltip) {
			this.visibleSubscribeTooltip.tooltipHintOpen = false;
		}
	}

	pastePhoneNumber(event) {
		let addParsedPhoneNumberToModel = (phoneNumber) => {
			let phoneNumberData = this.PhoneNumberParser.getParsedPhoneNumber(phoneNumber);
			this.$timeout(() => {
				if (phoneNumberData.recognized) {
					this.search.phoneNumber.area = phoneNumberData.area;
					this.search.phoneNumber.number = phoneNumberData.number;
				}
				this.search.phoneNumber.city = null;
				this.validatePhoneNumber();
			});
		};

		if (event.originalEvent.clipboardData) {
			addParsedPhoneNumberToModel(event.originalEvent.clipboardData.getData('text/plain'));
		} else {
			this.$timeout(() => {
				addParsedPhoneNumberToModel(event.currentTarget.value);
			});
		}
	}

	getActiveSearchModel() {
		return this.search[this.searchType.model];
	}

	validateSearchForms() {
		let searchModel = this.getActiveSearchModel();
		let valid = true;
		let notEnoughData = true;

		if (this.searchType == this.constants.SEARCH_TYPES.PERSONAL) {
			valid = searchModel.lastName || searchModel.firstName || searchModel.city || searchModel.street || searchModel.houseNum || false;
			notEnoughData = valid;
		} else if (this.searchType == this.constants.SEARCH_TYPES.PHONE_NUMBER) {
			valid = this.validatePhoneNumber();
		} else if (this.searchType == this.constants.SEARCH_TYPES.COMPANY) {
			valid = searchModel.name || searchModel.city || searchModel.street || searchModel.houseNum || false;
			notEnoughData = valid;
		}

		this.searchForm.$setValidity('notEnoughData', notEnoughData);
		return valid;
	}

	validatePhoneNumber() {
		let validationResult = this.OnlineTudakozoService.validatePhoneNumber(this.search.phoneNumber);
		for (let errorKey in validationResult.errorKeys.phoneArea) {
			this.searchForm.phoneArea.$setValidity(errorKey, validationResult.errorKeys.phoneArea[errorKey]);
		}
		for (let errorKey in validationResult.errorKeys.phoneNumber) {
			this.searchForm.phoneNumber.$setValidity(errorKey, validationResult.errorKeys.phoneNumber[errorKey]);
		}
		return validationResult.validPhoneNumber;
	}

	setPhoneNumberFieldState(input) {
		input.$setDirty();
		input.$commitViewValue();
		this.validatePhoneNumber();
	}

	validateOnSpecialConditions($event, input, isPhoneAreInput) {
		this.$timeout.cancel(this.search.phoneNumber.timeoutPromise);

		if ($event.which == this.constants.ENTER_KEYCODE) {
			input.$commitViewValue();
			this.validatePhoneNumber();
		} else if (input.$viewValue && ((isPhoneAreInput && input.$viewValue.length >= this.constants.PHONEAREA_ON_VALIDATE_MIN_LENGTH) || input.$viewValue.length >= this.constants.PHONENUMBER_ON_VALIDATE_MIN_LENGTH)) {
			this.search.phoneNumber.timeoutPromise = this.$timeout(() => {
				this.setPhoneNumberFieldState(input);
			}, this.constants.WAIT_PHONENUMBER_VALIDATION_IN_MS);
		}
	}

	phoneAreaChanged($event, input) {
		let oldViewValue = input.$viewValue;
		this.$timeout(() => {
			if (input.$viewValue != oldViewValue) {
				this.search.phoneNumber.city = null;
			}
		});
		this.validateOnSpecialConditions($event, input, true);
	}

	closeAutoSuggestWithTab($event, autoSuggestModel) {
		if ($event.which == this.constants.TAB_KEYCODE) {
			this.autoSuggestInputFocusChanged(autoSuggestModel, false);
		}
	}

	autoSuggestInputFocusChanged(autoSuggest, hasFocus) {
		let autoSuggestModel = this.getActiveSearchModel().autosuggest;

		for (let type in autoSuggestModel) {
			if (autoSuggestModel[type] && autoSuggestModel[type] != autoSuggest) {
				autoSuggestModel[type].showDropdown = false;
			} else {
				autoSuggest.showDropdown = hasFocus;
			}
		}
	}

	cityFieldTypingTimeoutWatcher(type) {
		let searchModel = this.getActiveSearchModel();
		this.getSuggestions(type);

		this.$timeout.cancel(this.cityCheckMatchTimeout);
		this.cityCheckMatchTimeout = this.$timeout(() => {
			let matchingCity = searchModel.autosuggest[type].suggestions.filter((suggestion) => suggestion.value.toLowerCase() == searchModel.city.toLowerCase());
			if (matchingCity.length == 0) {
				this.search.phoneNumber.cityNotFound = true;
			} else {
				this.search.phoneNumber.cityNotFound = false;
				this.selectSuggestion(matchingCity[0], type, true);
			}
		}, this.constants.AUTO_SUGGEST_CHECK_MATCH_TIMEOUT - this.constants.AUTO_SUGGEST_SUGGESSTION_TIMEOUT);
	}

	getSuggestions(type) {
		let searchModel = this.getActiveSearchModel();
		let isCitySearch = type == this.constants.AUTO_SUGGEST_TYPES.CITY;

		if (isCitySearch) {
			searchModel.cityValue = null;
		}

		if (this.autoSuggestCanceler) {
			this.autoSuggestCanceler.resolve();
		}

		if (searchModel.cityValue || searchModel.city || searchModel.street) {
			this.autoSuggestCanceler = this.OnlineTudakozoService.getAutoSuggestData(
				type.toUpperCase(),
				{
					city: searchModel.cityValue || searchModel.city,
					street: isCitySearch ? undefined : searchModel.street
				},
				(result) => {
					this.OnlineTudakozoService.initSuggestions(searchModel.autosuggest[type], result, isCitySearch);
				}
			);
		}

		this.validateSearchForms();
	}

	selectSuggestion(suggestion, type, userInteraction = false) {
		let searchModel = this.getActiveSearchModel();
		let autoSuggestModel = searchModel.autosuggest[type];

		if (!userInteraction) {
			this.autoSuggestInputFocusChanged(autoSuggestModel, false);
		}

		this.$timeout.cancel(this.cityCheckMatchTimeout);
		this.search.phoneNumber.cityNotFound = false;
		autoSuggestModel.suggestions = [];

		if (type == this.constants.AUTO_SUGGEST_TYPES.CITY) {
			searchModel.city = suggestion.suggest;
			searchModel.cityValue = suggestion.value;
			searchModel.area = suggestion.areaCode;
		} else {
			searchModel.street = suggestion;
		}

		this.validateSearchForms();
	}

	executeCaptchaWhenSubmit() {
		if (this.validateSearchForms()) {
			window.grecaptcha.execute(this.captcha.widgetId);
		}
	}

	startSearch() {
		this.searchResult = null;
		let searchParams = this.OnlineTudakozoService.getSearchParams(this.getActiveSearchModel(), this.searchType.key, this.captcha);

		if (this.validateSearchParams(searchParams, this.searchType.key)) {
			this.initLoader();
			this.searchCanceler = this.OnlineTudakozoService.getSearchResult(searchParams, (result) => {
				this.showLoader = false;
				this.$timeout.cancel(this.loaderTimeout);
				this.handleSearchResponse(result, searchParams);
			});
		}
		this.captchaExpired();
	}

	validateSearchParams(searchParams, searchType) {
		if (searchType == this.constants.SEARCH_TYPES.PERSONAL.key) {
			if (!searchParams.name1 && !searchParams.name2) {
				this.showLoader = true;
				this.$timeout(() => {
					this.searchResult = {
						nameRequired: true
					};
					this.showLoader = false;
				}, 200);
				return false;
			}
		}
		return true;
	}

	initLoader() {
		this.showLoader = true;

		this.loaderTimeout = this.$timeout(() => {
			this.showLoader = false;
			if (this.searchCanceler) {
				this.searchCanceler.resolve();
			}
			this.searchResult = {
				status: this.constants.SEARCH_STATUSES.ERROR
			};
		}, this.constants.SEARCH_TIMEOUT);
	}

	handleSearchResponse(result, searchParams) {
		this.searchResult = result;
		this.captcha.response = null;
		this.captcha.fail = false;

		if (!Array.isArray(this.searchResult.data.subscribers)) {
			this.searchResult.data.subscribers = [];
		}

		if (result.status == this.constants.SEARCH_STATUSES.SUCCESS) {
			this.searchResult.data.visibleRecordCount = this.searchResult.data.subscribers.length;
			this.searchResult.data.subscribers = this.SearchResultConverter.convert(this.searchResult.data.subscribers, searchParams);
			this.searchResult.hasMoreRecordsThanVisible = result.data.recordCount > this.searchResult.data.visibleRecordCount;
			this.searchResult.hasTooMuchRecords = result.data.recordCount >= this.constants.TOO_MUCH_RECORDS;
			this.searchResult.filterSnapshot = angular.copy(this.getActiveSearchModel());
			this.$scope.$broadcast('calculatePhoneNumberPositions');

			if (this.searchResult.data.subscribers.length) {
				this.searchResult.promo = this.PromoHandler.getPromoData();
				if (this.searchResult.promo) {
					let promoPosition = isNaN(window.promoPosition) ? this.constants.DEFAULT_PROMO_POSITION : window.promoPosition;
					let realPromoPosition = Math.min(this.searchResult.data.subscribers.length + 1, promoPosition);
					this.searchResult.promoPosition = Math.max(0, realPromoPosition - 1);
					this.TagManager.logPromoDisplay(this.searchResult.promo, this.searchResult.promoPosition + 1);
				}
			}

			this.initSticky();
			this.TagManager.logSearch(this.searchResult.filterSnapshot, this.searchType.key);

			this.$timeout(() => {
				this.ScrollHelper.scrollToResults();
			});
		} else if (result.status == this.constants.SEARCH_STATUSES.FAIL) {
			this.searchResult.data.subscribers = [];
			this.captcha.fail = result.data.code == this.constants.INVALID_CAPTCHA_CODE;
		}
	}

	initSticky() {
		this.stickyVisible = true;
		this.ScrollHelper.initScrollWatcher(() => {
			this.$timeout(() => {
				this.stickyVisible = false;
			});
		});
	}

	scrollToResults() {
		this.stickyVisible = false;
		this.ScrollHelper.scrollToResults(true);
	}

	toggleConnectedSubscribers(subscriber, state) {
		subscriber.showConnectedSubscribers = state;
		this.$scope.$broadcast('calculatePhoneNumberPositions');
	}

	saveVCard(subscriber) {
		let vCardContent = this.vCard.create(subscriber);
		let fileName = `${subscriber.name1} ${subscriber.name2}.vcf`;
		this.vCard.download(vCardContent, fileName);
	}

	logPromoClick() {
		this.TagManager.logPromoClick(this.searchResult.promo, this.searchResult.promoPosition + 1);
	}

	setCaptchaWidgetId(widgetId) {
		this.captcha.widgetId = widgetId;
	}

	startSearchAfterCaptchaResponseArrived(response) {
		this.captcha.response = response;
		this.startSearch();
	}

	captchaExpired() {
		this.captcha.show = false;
		this.$timeout(() => {
			this.captcha.show = true;
		});
	}

	get isMobileView() {
		return window.innerWidth < this.constants.MOBILE_BREAKPOINT;
	}
}

OnlineTudakozoController.$inject = ['Constants', '$scope', '$timeout', 'vcRecaptchaService', 'PhoneNumberParser', 'OnlineTudakozoService', 'ScrollHelper', 'SearchResultConverter', 'vCard', 'PromoHandler', 'TagManager'];

export default OnlineTudakozoController;
